$env: portal;
@import "./theme/portal-theme.scss";

.DataTable .ms-DetailsList {
  overflow: hidden;
}

.DataTable .DataTableBody.tiny-row-body button {
  min-height: auto;
  max-height: none;
  height: auto !important;
  padding-top: 1px;
  padding-bottom: 1px;
  padding-right: 1px;
  /* color: $white-color; */
}

.DataTable .scadenziario-portal-header-row {
  background-color: $main-color;
  color: $black-color;
  font-weight: bold;
}

.ms-FocusZone .ms-DetailsHeader {
  border: none!important;
}

.DataTable .scadenziario-portal-detail-row1 {
  background-color: $ultralight-grey-color;
  min-height: inherit;
  width: 100%;
  border: none;
}

.DataTable .scadenziario-portal-detail-row0:hover .IconSwitchComponent button:disabled, .DataTable .scadenziario-portal-detail-row1:hover .IconSwitchComponent button:disabled {
  background-color: $light-main-color;
}

.DataTable .is-selected.scadenziario-portal-nogroup-row1 {
  background-color: $light-grey-color;
}

.DataTable .scadenziario-portal-detail-row0:hover, .DataTable .scadenziario-portal-detail-row1:hover, .DataTable .is-selected.scadenziario-portal-detail-row0:hover, .DataTable .is-selected.scadenziario-portal-detail-row1:hover {
  background-color: $light-main-color;
}

.DataTable .scadenziario-portal-nogroup-row0:hover, .DataTable .scadenziario-portal-nogroup-row1:hover, .DataTable .is-selected.scadenziario-portal-nogroup-row0:hover, .DataTable .is-selected.scadenziario-portal-nogroup-row1:hover {
  background-color: $light-main-color;
}

.DataTable .scadenziario-portal-group-row1 {
  background-color: $ultralight-grey-color;
}

.DataTable .scadenziario-portal-group-row0:hover, .DataTable .scadenziario-portal-group-row1:hover, .DataTable .is-selected.scadenziario-portal-group-row0:hover, .DataTable .is-selected.scadenziario-portal-group-row1:hover {
  background-color: $light-main-color;
}

/* BACKGROUND IN ALTO A SX DELLE DATATABLE (ANCHE LA ELENCO ATTIVITà) */

.DataTable .DataTableBodyContainer .ms-DetailsHeader {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  border-bottom: 0px !important;
  background-color: $main-color;
}

.HomeOperativa-crud-taskList .DataTable .DataTableBodyContainer .ms-DetailsHeader {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  border-bottom: 0px !important;
  background-color: $white-color;
}

/* ALTRO */

.DataTable .ms-DetailsRow-fields {
  align-items: center !important;
}

.DataTable .required-0 {
  color: $black-color;
  text-decoration: none;
}

.DataTable .required-1 {
  color: $red-color;
  text-decoration: none;
  font-weight: bold;
}

.DataTable .background-color-0 {
  background-color: $dark-green-color!important;
}

.DataTable .color-0 {
  color: $green-color!important;
}

.DataTable .background-color-1 {
  background-color: $dark-yellow-color!important;
}

.DataTable .color-1 {
  color: $yellow-color !important;
}

.DataTable .background-color-2 {
  background-color: $dark-red-color !important;
}

.DataTable .color-2 {
  color: $red-color !important;
}

.DataTable .hover-link a:hover {
  color: $dark-grey-color !important;
}

.DamageDesk .DataTable .ms-DetailsRow-cell{
  padding: 0px 8px 0px 12px !important;
}

/* TITLE */

.DataTable .DataTableTitlePortal {
  background: $ultralight-grey-color;
  color: $white-color;
  height: 3px;
  margin-top: 40px;
  margin-bottom: 0px;
  z-index: 99;
}

.DataTable .DataTableTitlePortal .DataTableTitleContainer {
  display: flex;
  width: 100%;
  justify-content: space-between
}

.DataTable .DataTableTitlePortal span {
  padding: 10px;
  position: static;
  background-color: transparent;
}

.DataTable .DataTableTitlePortal .DataTableRightAngleLabel {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 39px 0 0 18px;
  border-color: transparent transparent transparent $ultralight-grey-color;
}

.DataTable .DataTableTitlePortal .DataTableLeftAngleLabel {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 39px 18px;
  border-color: transparent transparent $light-grey-color transparent;
  margin-left: auto;
}

.DataTable .DataTableTitlePortal {
  font-family: 'Roboto', sans-serif;
  font-size: 1.25em;
  text-transform: uppercase;
  color: $white-color;
  background-color: $white-color;
  height: 2.2em;
  min-height: 1px;
  max-height: 2.2em;
  margin: 0px;
  text-align: left;
  display: flex;
  border-bottom: 10px solid $light-grey-color;
  border-right: 10px solid $light-grey-color;
}

.DataTable .DataTableTitlePortal .title-string {
  padding: 10px 10px 10px 10px;
  position: relative;
  top: 0px;
  left: 0px;
  color: $text-header-color;
  background-color: $light-grey-color;
  font-size: 15px;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  border-bottom: 3px solid $text-header-color;
}

.DataTable .DataTableTitlePortal .DataTableActionContainer {
  width: auto;
  display: flex;
  flex-direction: row-reverse;
  background-color: $light-grey-color;
  border-bottom: 3px solid $text-header-color;
}

.DataTable .DataTableTitlePortal .top-right-button {
  align-items: center;
}

.DataTable .DataTableTitlePortal .top-right-button i {
  color: $text-header-color;
}

.DataTable .DataTableCommandBar i {
  color: $text-header-color;
}

.DataTable .DataTableToolbarOutherGrid .DataTableToolbarButton i {
  color: $text-header-color;
}

.DataTable .DataTableToolbarOutherGrid .DataTableToolbarButton:hover {
  color: $white-color;
  font-weight: 600;
}

/* BODY */

.scadenziario-portal-body {
  border-left: 10px solid $light-grey-color;
  border-right: 10px solid $light-grey-color;
}

.DataTablePagination {
  border-left: 10px solid $light-grey-color;
  border-right: 10px solid $light-grey-color;
  border-bottom: 10px solid $light-grey-color;
}

.DataTableToolbar .filterBar {
  background-color: $light-grey-color !important;
}

.DataTableToolbar .filterBox .ms-TextField-fieldGroup, .DataTableToolbar .filterBar .ms-ComboBox {
  border-radius: 1px !important;
}

/* HEADER TABLE*/

.scadenziario-portal-header-row .ms-DetailsHeader-cellTitle {
  padding: 0px;
  margin-right: 5px;
}

.DataTable .single-line {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.DataTable .red {
  color: $red-color;
}

.DataTable .green {
  color: $green-color;
}

.DataTable .classDaLeggere {
  font-weight: 700;
  color: $blue-color;
}

.DataTable .scadenziario-body {
  padding: 0px;
  background-color: $white-color !important;
}

/* MULTI SELECTION */

.WagxMultiSelector .DataTable .DataTableBodyContainer .DataTableBodyContent .CheckedButtonHidden .ms-Check {
  display: block;
}


.DataTable .ms-GroupSpacer {
  width: 0px!important;
}

.DataTable .ms-DetailsRow,
.DataTable .ms-DetailsHeader{
  width: 100%;
}


.dataTableTdMiddle32  .ms-Button--icon{
  padding: 0%;

}

.dataTableTdMiddle32  .ms-Button--icon .ms-Button-flexContainer {
  float:left;

}

.dataTableTdMiddle32 .ms-Button--icon .ms-Button-flexContainer .ms-Button-icon {
 margin: 0px;
}


/*-----*/

.wagx-generic-input .DataTable {
  margin-top: 10px;
}


.DataTable .DataTableToolbar .filterBoxdataRange-inline .ms-GridfilterBoxdataRange-inline .ms-Grid-row div:first-child{
  padding-left: 0px !important;
  padding-bottom: 0px !important;
}

.DataTable .DataTableToolbar .filterBoxdataRange-inline .ms-GridfilterBoxdataRange-inline .ms-Grid-row div:last-child{
  padding-right: 0px !important;
  padding-bottom: 0px !important;
}

// fix css per centrare il checkbox di selezione di una riga multiline

.DataTable .ms-DetailsRow-cellCheck{
  display: flex;
  align-items: center;
}