$env: portal;
@import "./theme/portal-theme.scss";

.GenericForm .GenericFormGroupVar4Title {
  background-color: $light-main-color;
  color: $text-header-color;
  height: 2em;
  font-size: 1em;
  margin-top: 0;
  display: flex;
  place-items: center;
  font-weight: bold;
  justify-content: center;
}

.GenericForm .GenericFormGroupVar4 {
  border: lightgray 1px solid !important;
  padding: 10px 0px 10px 0px;
}

.GenericForm .GenericFormGroupVar4 .ms-TextField-fieldGroup,
.GenericForm .GenericFormGroupVar4 .ms-ComboBox,
.GenericForm .GenericFormGroupWithSubGroupVar4 .ms-TextField-fieldGroup,
.GenericForm .GenericFormGroupWithSubGroupVar4 .ms-ComboBox,
.GenericForm .GenericFormSubGroupVar4 .ms-TextField-fieldGroup,
.GenericForm .GenericFormSubGroupVar4 .ms-ComboBox {
  border-radius: 1px;
}

.GenericForm .GenericFormGroupVar4 .buttonActionContainer {
  text-align: center;
}

.GenericForm .GenericFormSubGroupVar4Title {
  color: $dark-grey-color;
  height: 1em;
  font-size: 12px;
  margin-top: 0;
  font-weight: bold;
}

.GenericForm .GenericFormFirstSubGroupVar4Title {
  color: $dark-grey-color;
  height: 1em;
  font-size: 12px;
  margin-top: 0;
  font-weight: bold;
}

.GenericForm .GenericFormSubGroupVar4AlertTitle {
  color: $red-color;
  height: 1em;
  font-size: 1em;
  margin-top: 0;
  font-weight: bold;
  text-align: center;
}

.GenericForm .GenericFormFirstSubGroupVar4 {
  padding: 1em;
}

.GenericForm .GenericFormSubGroupVar4 {
  border-top: lightgray 1px solid !important;
  padding: 1em;
  margin: 15px 0px 15px 0px;
}

.GenericForm .GenericFormGroupTitle {
  margin-top: 5px;
}

.GenericForm .GenericFormGroupVar4 {
  border: none !important;
  padding: 10px 0px 10px 0px;
}

.GenericForm .GenericFormGroupVar4Title {
  background: $light-main-color;
  color: $text-header-color;
  height: 2em;
  font-size: 1em;
  margin-top: 0;
  display: -ms-flexbox;
  display: flex;
  place-items: center;
  font-weight: bold;
  -ms-flex-pack: center;
  justify-content: center;
}

.GenericForm .GenericFormFirstSubGroupVar4Title {
  color: $dark-grey-color;
  height: 1em;
  font-size: 12px;
  margin-top: 0;
  font-weight: bold;
}

/* GENERIC FORM TITLE */

.GenericForm .GenericFormTitleContainer {
  width: 80% !important;
}

.GenericForm .GenericFormActionContainer {
  display: flex;
  padding: 5px;
}

.GenericForm .GenericFormTitleBarDefault {
  background-image: linear-gradient($secondary-color, $secondary-color) !important;
}

.GenericForm .GenericFormTitleBarDefault .GenericFormTitleContainer {
  background-color: $secondary-color !important;
  color: $white-color !important;
}

.GenericForm .GenericFormPortalTitleBar .GenericFormTitleContainer {
  display: flex;
  position: relative;
  top: 0px;
  left: 0;
}

.GenericForm .GenericFormPortalTitleBar {
  background-color: $white-color;
  color: white;
  width: 100%;
  height: 40px;
  margin-top: 0px;
  margin-bottom: 0px;
  z-index: 99;
}

.GenericForm .GFHomeFix {
  padding-left: 10px;
}

.GenericForm .GenericFormPortalTitleBar .title-string {
  background-color: $dark-grey-color !important;
  padding: 10px 10px 10px 10px;
  position: relative;
  top: 0px;
  left: 0px;
  color: $white-color !important;
  font-size: 15px;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
}

.GenericForm .GenericFormRightAngleLabel {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 40px 0 0 20px;
  border-color: transparent transparent transparent $dark-grey-color;
}

.GenericForm .GenericFormPortalTitleBar .GenericFormActionContainer {
  display: flex;
  justify-content: flex-end !important;
}

.GenericForm .GenericFormActionContainer .top-right-button i {
  color: $dark-grey-color;
}

.GenericForm .generate-tax-code {
  padding-top: 20px;
}

.GenericForm .emphasizeDisabledText input,
.GenericForm .emphasizeDisabledText textarea {
  color: $ultradark-grey-color;
  font-size: 14px;
  font-weight: bold;
}

.GenericForm .emphasizeDisabledText label,
.GenericForm .ms-TextField-suffix {
  color: $ultradark-grey-color;
}

/* DATA TABLE IN GENERIC FORM */

.PortalForm {
  border: none;
}

.PortalForm .DataTable .DataTableBody.tiny-row-body button {
  min-height: auto;
  max-height: none;
  height: auto !important;
  padding-top: 1px;
  padding-bottom: 1px;
  padding-right: 1px;
  /* color: $white-color; */
}

.PortalForm .DataTable .scadenziario-header-row {
  background-color: $light-grey-color;
  color: $dark-grey-color;
}

.PortalForm .ms-FocusZone .ms-DetailsHeader {
  border: none !important;
}

.PortalForm .DataTable .scadenziario-detail-row1 {
  font-weight: lighter;
  background-color: #dfe2e6;
  min-height: inherit;
  width: 100%;
  border: none;
}

.PortalForm .DataTable .is-selected.scadenziario-nogroup-row1 {
  background-color: $light-grey-color;
}

.PortalForm .DataTable .scadenziario-detail-row0:hover,
.PortalForm .DataTable .scadenziario-detail-row1:hover,
.PortalForm .DataTable .is-selected.scadenziario-detail-row0:hover,
.PortalForm .DataTable .is-selected.scadenziario-detail-row1:hover {
  background-color: #eae9e2;
}

.PortalForm .DataTable .scadenziario-nogroup-row0:hover,
.PortalForm .DataTable .scadenziario-nogroup-row1:hover,
.PortalForm .DataTable .is-selected.scadenziario-nogroup-row0:hover,
.PortalForm .DataTable .is-selected.scadenziario-nogroup-row1:hover {
  background-color: #eae9e2;
}

.PortalForm .DataTable .scadenziario-group-row1 {
  background-color: $ultralight-grey-color;
}

.PortalForm .DataTable .scadenziario-group-row0:hover,
.PortalForm .DataTable .scadenziario-group-row1:hover,
.PortalForm .DataTable .is-selected.scadenziario-group-row0:hover,
.PortalForm .DataTable .is-selected.scadenziario-group-row1:hover {
  background-color: #eae9e2;
}

.PortalForm .GenericInputLabelValue {
  text-align: left;
  width: 62%;
  display: inline-block;
  color: $dark-grey-color;
  font-weight: bold;
  padding-top: 1px 0px 1px 0px;
  font-size: 12px;
  user-select: all;
}

.GenericForm .emphasizeDisabledTextGreen input {
  color: $ultradark-grey-color;
  font-size: 14px;
  font-weight: bold;
  color: $green-color;
}

.GenericForm .emphasizeDisabledTextRed input {
  color: $ultradark-grey-color;
  font-size: 14px;
  font-weight: bold;
  color: $red-color;
}

.GenericForm .emphasizeDisabledText label,
.GenericForm .emphasizeDisabledTextGreen label,
.GenericForm .emphasizeDisabledTextRed label {
  color: $ultradark-grey-color;
}

/* PIVOT*/

.PivotTop {
  display: flex;
}

.PivotTitleBar {
  padding-left: 10px;
  background-color: $white-color;
  color: $white-color;
  width: 100%;
  height: 40px;
  margin-top: 0px;
  margin-bottom: 0px;
  z-index: 99;
}

.PivotTitleContainer {
  display: flex;
  position: relative;
  top: 0;
  left: 0;
}

.PivotTitleBar .title-string {
  background-color: $dark-grey-color;
  padding: 10px;
  position: relative;
  top: 0px;
  left: 0px;
  color: $white-color;
  font-size: 15px;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
}

.PivotTitleBar .PivotRightAngleLabel {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 40px 0 0 20px;
  border-color: transparent transparent transparent $dark-grey-color;
}

.ms-Pivot--tabs button {
  margin: 0px 10px 0px 10px;
}

.ms-Pivot--tabs .ms-Pivot-text {
  width: 200px;
}

.ms-Pivot {
  z-index: 100;
  width: 98%;
  display: flex;
}

.ms-Pivot-link {
  font-weight: 200;
  cursor: pointer;
  background-color: $light-grey-color !important;
  color: $dark-grey-color !important;
}

.ms-Pivot-link:active {
  background-color: $light-grey-color !important;
}

.ms-Pivot-link.is-selected {
  font-weight: 600;
  background-color: $ultralight-grey-color !important;
  color: $dark-grey-color !important;
}

.ms-Pivot-link.is-selected::before {
  border-bottom: 2px solid $main-color !important;
}

/* MULTI SELECTION */

.WagxMultiSelector {
  padding: 20px 15px 20px 15px;
}

.WagxMultiSelectorButtonContainer button.buttonEnabled {
  background-color: $main-color !important;
}

/* RESET PSW */

.PasswordResetForm .input-element label {
  color: $dark-grey-color !important;
}

.wagx-generic-form-read-only-body>.wagx-generic-form-group-read-only>.wagx-generic-form-group-read-only-header {
  background-color: $light-main-color !important;
  color: $dark-grey-color !important;
  text-transform: uppercase;
}

.wagx-generic-form-read-only>.wagx-generic-form-read-only-header {
  display: none !important;
}

/* WAGX ACCORDION */

.WagxElementAccordion .WagxElementAccordionBody .WagxElementAccordionElement.ms-Grid-row {
  display: flex;
  align-items: center;
}

.WagxElementAccordion .WagxElementAccordionBody .WagxElementAccordionElement.ms-Grid-row .ms-Grid-col.ms-sm1 {
  display: flex;
  justify-content: center;
  padding-top: 10px;
}

.WagxElementAccordion .WagxElementAccordionBody .WagxElementAccordionElement.ms-Grid-row .ms-Grid-col.ms-sm1 i {
  font-size: 25px;
}

.WagxElementAccordion .WagxElementAccordionBody .WagxElementAccordionElement.divider {
  border-top: 2px solid $main-color !important;
  margin: 10px 0px 10px 0px !important;
}

.wagx-generic-form-read-only.default-theme .wagx-generic-form-read-only-body .wagx-generic-form-group-read-only .wagx-generic-form-group-read-only-header {
  text-align: center !important;
}