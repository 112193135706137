.GenericInputLabelTitle {
  width: 150px;
  display: inline-block;
}

.GenericInputLabelTitle.autoWidth {
  width: auto !important;
}

.GenericInputLabelValue {
  font-weight: normal;
  text-align: right;
  display: inline-block;
}

.margin-bottom-10 {
  margin-bottom: 10px !important;
}

.margin-bottom-5 {
  margin-bottom: 5px !important;
}

.margin-top-5 {
  margin-top: 5px !important;
}

.margin-bottom-2 {
  margin-bottom: 2px !important;
}

.margin-top-2 {
  margin-top: 2px !important;
}

.groupedInputClass div, .groupedInputClass label {
  display: inline;
}

.groupedInputClass div button {
  height: auto;
}

.buttonActionBadgeContainer {
  position: relative;
}

.buttonActionBadge {
  margin-left: -2px;
  padding: 0px 5px;
  background-color: red;
  color: white;
  position: absolute;
  border-radius: 9px;
  z-index: 1;
}

.cdTopIconForward {
  font-size: 1em;
  cursor: default;
}

.cdTopIcon {
  font-size: 1.10em;
}

.cdTopIcon.default {
  cursor: default;
}

.cdTopIcon.pointer {
  cursor: pointer;
}

.cdTopIcon.ROSSO {
  color: red;
}

.cdTopIcon.ARANCIONE {
  color: #c0c000;
}

.cdTopIcon.GIALLO {
  color: yellow;
}

.cdTopIcon.VERDE {
  color: green;
}

.cdTopIcon.BLU {
  color: blue;
}

.cdCompagniaSogessurIcon {
  font-size: 1.6em;
}

.cdCompagniaSogessurIcon.lightBlue {
  color: rgb(29, 93, 148);
}

.cdCompagniaSogessurIcon.BlackBoxIcon {
  position: relative;
  top: -8px;
}

.cdBarraSuperioreRivalse-RECUPERATA {
  color: green;
}

.cdBarraSuperioreRivalse-RISERVA-RECUPERO {
  color: #c0c0c0;
}

.GenericInputLabelValue a,
.GenericInputLabelValue button.wagx-link {
  cursor: pointer;
  text-decoration: underline;
}

.bold {
  font-weight: bold;
}

.GenericInputLabelValue.GenericInputValue-marginLeft10 {
  margin-left: 10px;
}

.GenericInputLabelValue.GenericInputValue-marginRight10 {
  margin-right: 10px;
}

.height15 {
  height: 15px;
}

.deskSpecificitaCardif .GenericInputLabelValue.statoCardif-orange {
  color: orange;
}

.deskSpecificitaCardif .GenericInputLabelValue.statoCardif-green {
  color: green;
}

.deskSpecificitaCardif .GenericInputLabelValue.statoCardif-red {
  color: red;
}

.GenericInputLabelValue.toUpperCaseValue {
  text-transform: uppercase;
}

.GenericForm .GenericFormBody .ms-TextField .ms-TextField-errorMessage, .GenericForm .GenericFormBody .DatePickerContainer.invalidBox p {
  display: none;
}

.invalidBox div,
.invalidBox div:hover,
.invalidBox .ms-ChoiceField-field::before {
  border-color: rgb(168, 0, 0) !important;
}

.invalidBox div.ms-Checkbox-checkbox {
  background-color: rgb(168, 0, 0) !important;
}

.warningBox div,
.warningBox div:hover {
  border-color: orange;
}

label.label-double-line {
  height: 38.8px;
  display: flex;
  align-items: flex-end;
}

.ToggleContainer.invalidBox div {
  border-color: transparent;
}

.ToggleContainer.invalidBox button {
  border-color: rgb(168, 0, 0);
}

.ToggleContainer.warningBox div {
  border-color: transparent;
}

.ToggleContainer.warningBox button {
  border-color: orange;
}

.ToggleContainer .ms-Toggle {
  margin-top: 5px;
  margin-bottom: 0px;
}

input:-webkit-autofill {
  background-color: yellow !important;
}

.TextInputReadOnly .ms-TextField-fieldGroup {

  border-style: dashed;
  background-color: rgba(255, 255, 255, 0.6);
}