.DataTableToolbar .filterBar {
  background-color: rgba(28, 152, 213, 0.1);
  border-bottom: solid 1px #edefee;
  padding: 5px;
}

.DataTableToolbar .action-button-container,
.DataTableToolbar .action-button-container button {
  height: 25px;
  text-align: center;
  padding: 0;
  margin: 0;
}

.DataTableToolbar .filterBox {
  padding: 2.5px;
}

.DataTableToolbar .filterBox .ms-TextField-fieldGroup,
.DataTableToolbar .filterBox .ms-TextField-fieldGroup input,
.DataTableToolbar .filterBox .ms-ComboBox {
  border-radius: 10px;
}

/* UTILIZZATA PER ALLINEARE VERTICALMENTE LE INPUT "RANGE" NEI FILTRI DI DATATABLE */
.DataTableToolbar .filterBox .ms-Grid-col:nth-of-type(odd) {
  padding-right: 2.5px;
}

.DataTableToolbar .filterBox .ms-Grid-col:nth-of-type(even) {
  padding-left: 2.5px;
}