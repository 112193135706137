$env: portal;
@import "./theme/portal-theme.scss";

body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  overflow-x: hidden;
}

.full-height {
  height: 100% !important;
}

html,
#root,
.root-51,
.App {
  height: 100%;
}

.AppContentBody {
  height: calc(100% - 5em);
}

.Popup .AppContentBody {
  height: calc(100% - 4em - 20px) !important;
  overflow-y: auto;
}

/* CUSTOMIZATONS */

.AppLoader .ImageContainer {
  background-image: url('app/customizations/images/logo_wagx_web_login.' +$env+'.png') !important;
  @if ($env =='portal-clever' ) {
    background-size: 100% !important;
  }
}

.LoginFormContainer a {
  // portal n4c
  // color: $dark-grey-color!important;

  // portal clever 
  color: $secondary-color  !important;
}

.LoginForm {
  background-color: $light-grey-color  !important;
  border-radius: 0.5px !important;
}

.LoginForm .LoginText {

  // portal n4c
  // color: $dark-grey-color!important;

  // portal clever 
  color: $text-header-color  !important;

}

.LoginForm .ms-MessageBar {
  background-color: $light-grey-color  !important;
}

.Login {
  background-color: #fff !important;
  background-image: none !important;
}

.Login .LoginButton button {

  // portal n4c
  // background-color: $dark-grey-color!important;

  // portal clever
  background-color: $secondary-color  !important;


  border-radius: 0.5px !important;
  color: #fff !important;
}

.Login .ImageContainer {
  @if ($env =='portal') {
    padding: 6em !important;
  }
  @else if ($env =='portal-clever') {
    padding: 3em !important;
  }
  background-image: url('app/customizations/images/logo_wagx_web_login.' +$env+'.png') !important;
  /* background-size: 65%!important; */
  @if ($env =='portal-clever') {
    background-size: 100% !important;
  }
}

.LoginElement label {

  //portal n4c
  // color: $dark-grey-color!important;

  //portal clever
  color: $secondary-color  !important;


  font-weight: normal !important;
}

.LoginElement .ms-TextField-fieldGroup {
  background-color: #fff !important;
  border-radius: 1px !important;
}

.WagxAccountPasswordForgotForm .input-element label {
  //portal n4c
  // color: $dark-grey-color!important;
  //portal clever
  color: $secondary-color  !important;

}

.WagxAccountPasswordForgotForm .input-element .ms-TextField-fieldGroup {
  background-color: #fff !important;
  border-radius: 1px !important;
  height: 3em;
  width: 30em;
}

.NavBarRightContainer {
  padding-top: 0.4em !important;
}

.NavBarRightContainer .WagxWebNavBarIcon {
  color: $tertiary-color  !important;
}

.NavBarRightContainer .WagxWebNavBarIcon:hover {
  color: $tertiary-color  !important;
}

.NavBarContainer {
  background-image: url('app/customizations/images/logo_wagx_web.' +$env+'.png') !important;
  @if ($env =='portal') {
    background-size: 350px !important;
  }
  @else if ($env =='portal-clever') {
    background-size: 450px!important;
  }
  padding: 0px !important;
  height: 100% !important;
}

.NavBarContainer .ms-Persona-primaryText {
  color: white !important;
}

.NavBarContainer .ms-Persona-primaryText:hover {
  color: $ultralight-grey-color  !important;
}

.NavBarContainer .ms-Persona-secondaryText {
  color: white !important;
}

.NavBarContainer .ms-Persona-secondaryText:hover {
  color: $ultralight-grey-color  !important;
}

/* BODY */

.scadenziario-portal-body {
  border-left: 10px solid $ultralight-grey-color;
  border-right: 10px solid $ultralight-grey-color;
}

.DataTablePagination {
  border-left: 10px solid $ultralight-grey-color;
  border-right: 10px solid $ultralight-grey-color;
  border-bottom: 10px solid $ultralight-grey-color;
}

.HomeOperativa .filtri {
  width: 360px !important;
  border-left: 10px solid $light-grey-color;
  border-bottom: 10px solid $light-grey-color;
  border-top: 10px solid $light-grey-color;
  margin-top: 38px;
  height: calc(100vh - 5.5em - 58px);
}

.HomeOperativa .tabellaDettaglio .DataTable {
  height: 100%;
}

.HomeOperativa .searchButton {
  background-color: $light-grey-color;
}

.HomeOperativa .searchButton div:first-child {
  margin: 0px 0px 10px 0px !important;
}

.HomeOperativa .searchButton .ms-TextField-fieldGroup {
  border-radius: 1px;
  border-color: $dark-grey-color;
}

/* CALENDAR */

.HomeOperativa .CalendarContainer {
  padding-top: 0px !important;
}

.HomeOperativa .CalendarFront .CalendarPastEvents,
.HomeOperativa .CalendarBack .CalendarPastEvents {
  border-top: none !important;
  border-bottom: 1px solid #d32027 !important;
}

.HomeOperativa .CalendarContainer .Day {
  border-bottom: 1px solid $ultralight-grey-color  !important;
}

.HomeOperativa .CalendarFront .Icon,
.HomeOperativa .CalendarFront .Description,
.HomeOperativa .CalendarFront .Counter,
.HomeOperativa .CalendarBack .Icon,
.HomeOperativa .CalendarBack .Description,
.HomeOperativa .CalendarBack .Counter {
  color: #d32027 !important;
}

.HomeOperativa .CalendarFront .CalendarNextEvents {
  border-bottom: 1px solid $ultralight-grey-color  !important;
}

.HomeOperativa .CalendarFront .CalendarNextEvents .Counter {
  /* background-color: #fff!important; */
  background-color: rgba(211, 32, 39, 0.3) !important;
}

.HomeOperativa .Calendar .CalendarDataViewContainer .Calendar-crud-dataList .ms-DetailsRow-fields {
  border-bottom: 1px solid $ultralight-grey-color  !important;
}

/* FIX */

.HomeOperativa-crud-taskList .DataTable .ms-ScrollablePane--contentContainer {
  overflow-x: hidden !important;
}

.HomeOperativa .applyButtonContainer {
  display: none;
}

.HomeOperativa .applyButtonContainer button {
  border: none;
}

.HomeOperativa .HomeOperativa-crud-taskList .ms-DetailsRow .ms-DetailsRow-cell {
  border-bottom: 1px solid $ultralight-grey-color  !important;
}

.HomeOperativa .HomeOperativa-crud-taskList .ms-DetailsRow .DataTableTd {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  user-select: none;
}

/*SWITCH */

.ms-Toggle-background {
  background-color: #fff;
}

.ms-Check.is-checked::before {
  background-color: $main-color;
}

.is-checked .ms-Toggle-background {
  background-color: $dark-grey-color;
}

.ms-Toggle-thumb {
  background-color: $dark-grey-color;
}

.ms-Toggle-thumb:hover {
  background-color: $main-color;
}

.is-checked .ms-Toggle-thumb {
  background-color: #fff;
}

/* MESSAGGI DI SUCCESSSO */

.MessageModal .messageModalButtons button {
  background-color: $dark-grey-color  !important;
}

/* PAGINAZIONE DATATABLE */

.DataTablePagination .ms-OverflowSet .ms-OverflowSet-item .ms-bgColor-themePrimary {
  background-color: $main-color  !important;
  color: black;
}

.DataTablePagination .ms-CommandBar-secondaryCommand i {
  color: $dark-grey-color  !important;
}

/* SPINNER */

.ms-Spinner-label {
  color: $dark-grey-color  !important;
}

.ms-Spinner-circle {
  border-color: $main-color #f2f2f2 #f2f2f2 !important;
}

/* FIX LAYOUT SYSTEM */

.GenericForm .GenericFormTitleBar,
.DataTable .DataTableTitle {
  background: $dark-grey-color;
}

.DataTable .DataTableTitle span,
.GenericForm .GenericFormTitleBar span {
  background: $dark-grey-color;
}

.ms-ContextualMenu-list i {
  color: $dark-grey-color;
}

.ms-ContextualMenu-item :hover {
  background-color: $light-main-color;
}

.ErrorBoundary {
  border-radius: 1px !important;
}

.MenuBar .HiddenIcon {
  overflow: hidden !important;
}

.MenuBar .MenuBarIconsContainer {
  border-bottom: 2px solid white !important;
}

/* WAGX GENERIC FORM READ ONLY */

.wagx-generic-form-read-only.default-theme .wagx-generic-form-read-only-body .wagx-generic-form-group-read-only .wagx-generic-form-group-read-only-body .wagx-generic-form-group-row-read-only .wagx-element-read-only .wagx-generic-input-value,
.wagx-generic-form-read-only.default-theme .wagx-generic-form-read-only-body .wagx-generic-form-group-read-only .wagx-generic-form-group-read-only-body .wagx-generic-form-group-row-read-only .grouped-wagx-element-read-only .wagx-generic-input-value,
.wagx-generic-form-read-only.default-theme .wagx-generic-form-read-only-body .wagx-generic-form-group-read-only .wagx-generic-form-group-read-only-body .wagx-generic-form-group-row-read-only .wagx-element-read-only .wagx-generic-input-value a,
.wagx-generic-form-read-only.default-theme .wagx-generic-form-read-only-body .wagx-generic-form-group-read-only .wagx-generic-form-group-read-only-body .wagx-generic-form-group-row-read-only .grouped-wagx-element-read-only .wagx-generic-input-value a {
  color: black !important;
}

.wagx-generic-form-read-only.default-theme .wagx-generic-form-read-only-body .wagx-generic-form-group-read-only .wagx-generic-form-group-read-only-body .wagx-generic-form-group-row-read-only .wagx-element-read-only .wagx-generic-input-title.noteReadOnly {
  width: 16% !important;
}

.wagx-generic-form-read-only.default-theme .wagx-generic-form-read-only-body .wagx-generic-form-group-read-only .wagx-generic-form-group-read-only-body .wagx-generic-form-group-row-read-only .wagx-element-read-only .wagx-generic-input-value.noteReadOnly {
  width: 84% !important;
  white-space: unset;
}

/* SPLITTER */

.wagxSplitter {
  height: calc(100% - 53px) !important;
}

/* FIX DATETIMEPICKER MATERIAL UI*/

.dateTimePickerStyle {
  width: auto !important;
}

/* SUPER FIX */

.ms-Grid-row {
  margin: 0px !important;
}

.ms-Grid {
  padding: 0px !important;
}

.WagxUserPicker-textfield-container .ms-TextField-field {
  color: #fff !important;
}


.wagx-generic-form-read-only.default-theme .wagx-generic-form-read-only-body .wagx-generic-form-group-read-only .wagx-generic-form-group-read-only-body .wagx-generic-form-group-row-read-only .wagx-element-read-only .wagx-generic-input-value.sinistro-css {
  color: #d32027 !important;
}

// fix icona Google nella label input indirizzi
.GooglePlacesAutocompleteGoogleLogo{
  height: 14px !important;
}

// fix css Broadcast Message

.broadcast-message-button {
  background-color: $secondary-color !important;
}

// fix modale CRUD Component riga 540

.ms-Dialog-action .ms-Button--primary{
  background-color: $secondary-color !important;
}

.ms-Dialog-action .ms-Button--primary:hover {
  background-color: $hover-color !important;
  color: white;
}

.ms-Dialog-actionsRight{
  display: flex !important;
  justify-content: space-around !important;
}


/* VERTICAL_WIZARD  */

.crea-incarico-vertical-wizard .VerticalWizard {
  height: calc(100% - 50px - 4em) !important;
}

.VerticalWizardContent> div {
  width: auto;
  margin: 5px;
  padding: 0.8em;
  box-shadow: 0 0 0.5em 0 rgba(0, 0, 0, 0.5);
  border: 1px solid #aaa;
  border-radius: 0.3em;
  background-color: #FFF;
}

.VerticalWizardBreadCumb {
  background: $main-color !important;
}


.VerticalWizardBreadCumb .BreadCumbButton.Active.First,
.VerticalWizardBreadCumb .BreadCumbButton.Active.Middle,
.VerticalWizardBreadCumb .BreadCumbButton.Middle.Active {
  background: $main-color !important;
}

.VerticalWizardContent .wagx-generic-form .wagx-generic-form-header {
  height: 25px;
}

.VerticalWizardContent .wagx-generic-form .wagx-generic-form-header .wagx-generic-title {
  background-image: none;
  background-size: initial;
  background-repeat: initial;
  background-position: initial;
  height: 100%;
}

.VerticalWizardContent .wagx-generic-form .wagx-generic-form-header .wagx-generic-title .wagx-generic-title-container {
  padding-left: initial;
  padding-right: initial;
  background-color: initial;
  color: initial;
  font-weight: initial;
  margin-left: initial;
  height: 100%;
}

.VerticalWizardContent .wagx-generic-form .wagx-generic-form-header .wagx-generic-title .wagx-generic-title-container> :first-child {
  font-weight: bold;
}

.VerticalWizardSaveAndProceedContainer button.is-disabled {
  width: auto;
  color: $white-color !important;
  background-color: $light-grey-color !important;
}

.VerticalWizardSaveAndProceedContainer button {
  width: auto;
  color: $white-color !important;
  background-color: $main-color !important;
}

.wagx-generic-form .wagx-generic-form-body {
  height: calc(100% - 90px) !important;
  width: 100% !important;
  overflow-y: auto !important;
  padding: 0 !important;
}

