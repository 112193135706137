$env: portal;
@import "./theme/portal-theme.scss";

.ms-Modal .GenericForm, .ms-Modal .DataTable {
  padding: 10px;
}

/* GENERIC FORM MODAL */

.ms-Modal .GenericForm .GenericFormTitleContainer {
  display: flex;
  position: relative;
  top: -22px !important;
  left: -8px;
}

.ms-Modal .GenericForm .GenericFormTitleBar {
  background-color: $white-color;
  color: $white-color;
  width: 100%;
  height: 3px;
  margin-top: 40px;
  margin-bottom: 0px;
  z-index: 99;
}

.ms-Modal .GenericForm .GenericFormTitleBar .title-string {
  background-color: $dark-grey-color;
  padding: 10px 10px 10px 10px;
  position: relative;
  top: 0px;
  left: 0px;
  color: $white-color;
  font-size: 15px;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
}

.ms-Modal .GenericForm .GenericFormRightAngleLabel {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 40px 0 0 20px;
  border-color: transparent transparent transparent $dark-grey-color;
}

.ms-Modal .GenericForm .GenericFormActionContainer {
  display: flex;
  justify-content: flex-end;
}

.ms-Modal .GenericForm .GenericFormGroupVar4Title {
  background: $light-main-color;
  color: $text-header-color;
  height: 2em;
  font-size: 1em;
  margin-top: 0;
  display: -ms-flexbox;
  display: flex;
  place-items: center;
  font-weight: bold;
  -ms-flex-pack: center;
  justify-content: center;
}

.ms-Modal .GenericForm .GenericFormFirstSubGroupVar4Title {
  color: $dark-grey-color;
  height: 1em;
  font-size: 12px;
  margin-top: 0;
  font-weight: bold;
}

.ms-Modal .GenericForm .GenericFormSubGroupVar4 {
  border-top: lightgray 1px solid!important;
  padding: 1em;
  margin: 15px 0px 15px 0px;
}

.ms-Modal .GenericForm .GenericFormGroupVar4 {
  border: none!important;
  padding: 10px 0px 10px 0px;
}

.ms-Modal .PlaceHolder label {
  text-align: left;
}

.ms-Modal .search-body-children {
  margin-top: 0px !important;
}

.ms-Modal .DataTable .DataTableTitle {
  padding: 0;
}

.ms-Modal .closeButton {
  z-index: 100 !important;
  position: absolute;
  right: 10px;
  top: 10px;
}

/* CONFIRM MODAL */

.confirmModal .ms-Dialog-main {
  border-radius: 1px!important;
  width: auto!important;
}

/* MESSAGGE MODAL */

.MessageModal {
  border-radius: 1px!important;
}

/* RICERCA GLOBALE */

.ms-Modal .DataTableTitle {
  background: $dark-grey-color;
  color: $white-color;
  height: 3px;
  margin-top: 40px;
  margin-bottom: 0px;
  z-index: 99;
}

.ms-Modal .DataTableTitleContainer {
  display: flex;
  width: 80%;
}

.ms-Modal .DataTable .DataTableTitle span {
  padding: 10px;
  position: static;
  background-color: transparent;
}

.ms-Modal .DataTable .DataTableRightAngleLabel {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 38.5px 0 0 18px;
  border-color: transparent transparent transparent $dark-grey-color;
}

.ms-Modal .DataTable .DataTableTitle {
  font-family: 'Roboto', sans-serif;
  font-size: 1.25em;
  text-transform: uppercase;
  color: $white-color;
  background-color: transparent;
  height: 2.2em;
  min-height: 1px;
  max-height: 2.2em;
  margin: 0px;
  text-align: left;
  display: flex;
}

.ms-Modal .DataTable .DataTableTitle {
  padding-top: 5px;
}

.ms-Modal .DataTable .DataTableTitle .title-string {
  padding: 10px 10px 10px 10px;
  position: relative;
  top: 0px;
  left: 0px;
  color: $white-color;
  background-color: $dark-grey-color;
  font-size: 15px;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
}

.ms-Modal .DataTable .DataTableActionContainer {
  width: 20%;
  display: flex;
  flex-direction: row-reverse;
}

.ms-Modal .DataTable .top-right-button {
  align-items: center;
}

.ms-Modal .DataTable .top-right-button i {
  color: $dark-grey-color;
}

.ms-Modal .scadenziario-portal-body {
  border: none;
}

.ms-Modal .DataTablePagination {
  border: none;
}

.ms-Modal .DataTableToolbar .filterBar {
  background-color: rgba(203, 207, 209) !important;
}

.longOperationWaitingModal {
  border-radius: 1px!important;
}

/* CLOSE BUTTON */

.search-body .DataTableActionContainer {
  padding-right: 30px;
}

.SearchResultModal {
  border-radius: 1px;
  padding: 0 !important;
}

/* SEARCH MODAL*/

.ms-Modal .SearchResultModal .PivotTop {
  display: flex;
  padding: 10px 10px 0px 10px;
  height: 0px;
}

.ms-Modal .SearchResultModal .PivotTitleBar {
  background-color: $white-color;
  color: $white-color;
  width: 100%;
  height: 0px;
  margin-bottom: 0px;
  z-index: 99;
}

.ms-Modal .SearchResultModal .PivotTitleContainer {
  display: flex;
  position: relative;
  top: -40px !important;
  left: 0;
}

.ms-Modal .SearchResultModal .PivotTitleBar .title-string {
  background-color: $dark-grey-color;
  padding: 10px 10px 10px 10px;
  position: relative;
  top: 0px;
  left: 0px;
  color: $white-color;
  font-size: 15px;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
}

.ms-Modal .SearchResultModal .PivotRightAngleLabel {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 40px 0 0 20px;
  border-color: transparent transparent transparent $dark-grey-color;
}

.ms-Modal .SearchResultModal .ms-Pivot {
  z-index: 100;
  width: 98%;
}

.ms-Modal .SearchResultModal .ms-Pivot-link {
  font-weight: 200;
  cursor: pointer;
}

.ms-Modal .SearchResultModal .ms-Pivot-link:active {
  background-color: $light-grey-color;
}

.ms-Modal .SearchResultModal .ms-Pivot-link.is-selected {
  font-weight: 400;
}

.ms-Modal .SearchResultModal .ms-Pivot-link.is-selected::before {
  border-bottom: 2px solid $dark-grey-color !important;
}

.ms-Modal .SearchResultModal .dataRange-inline .ms-Grid-col:first-child{
  padding-left: 0px;
  padding-right: 4px;
}

.ms-Modal .SearchResultModal .dataRange-inline .ms-Grid-col:last-child{
  padding-left: 4px;
  padding-right: 0px;
}

.ms-Modal .noteTextArea textarea {
  color: $ultradark-grey-color;
  font-size: 12px;
}

.ms-Modal .confirm-modal .confirmModalScrollableContent{
  padding: 1em;
}

.ms-Modal .confirmModalScrollableContent .confirmModalButtons,
.ms-Modal .ms-Modal-scrollableContent .confirm-modal-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0px;
  width: 100%;
}

.ms-Modal .confirmModalScrollableContent .confirmModalButtons .confirm,
.ms-Modal .ms-Modal-scrollableContent .confirm-modal-footer .confirm {
  background-color: $green-color;
  color: $white-color;
  margin: 5px;
  flex-grow: 0.1;
}

.ms-Modal .confirmModalScrollableContent .confirmModalButtons .deny,
.ms-Modal .ms-Modal-scrollableContent .confirm-modal-footer .undo {
  background-color: $red-color;
  color: $white-color;
  margin: 5px;
  flex-grow: 0.1;
}

.ms-Modal .confirmModalScrollableContent .confirmModalButtons .confirm:hover,
.ms-Modal .ms-Modal-scrollableContent .confirm-modal-footer .confirm:hover {
  background-color: $dark-green-color;
}

.ms-Modal .confirmModalScrollableContent .confirmModalButtons .deny:hover,
.ms-Modal .ms-Modal-scrollableContent .confirm-modal-footer .undo:hover {
  background-color: $dark-red-color;
}

.SearchResultModal .closeButton{
  right: 20px !important;
}

.SearchResultModal .GenericForm .GenericFormTitleBarDefault .GenericFormTitleContainer {
  display: flex;
  position: relative;
  top: -22px;
  left: -8px;
}

.SearchResultModal .GenericForm .GenericFormTitleBarDefault {
  background-color: $white-color;
  color: white;
  width: 100%;
  height: 3px;
  margin-top: 40px;
  margin-bottom: 0px;
  z-index: 99;
}

.SearchResultModal .GenericForm .GenericFormTitleBarDefault .title-string {
  background-color: $dark-grey-color;
  padding: 10px 10px 10px 10px;
  position: relative;
  top: 0px;
  left: 0px;
  color: $white-color;
  font-size: 15px;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
}

.SearchResultModal .GenericForm .GenericFormRightAngleLabel {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 40px 0 0 20px;
  border-color: transparent transparent transparent $dark-grey-color;
}

.SearchResultModal .GenericForm .GenericFormTitleBarDefault .GenericFormActionContainer {
  display: flex;
  justify-content: flex-end !important;
}

.SearchResultModal .GenericForm .GenericFormTitleBarDefault{
  background-image: none !important;
}

.SearchResultModal .GenericForm .GenericFormTitleBarDefault .GenericFormTitleContainer, 
.SearchResultModal .GenericForm .GenericFormTitleBarDefault .GenericFormActionContainer{
  background-color: white !important;
  margin-left: 0px;
  height: 40px;
  line-height: auto;
}

// Modale di errore (componente ErrorBoundary)

.error-boundary .error-boundary-header {
  background: $main-color !important;
}

.error-boundary .error-boundary-footer button.primary {
  background-color: $secondary-color !important;
  color: white;
}

.error-boundary .error-boundary-footer button.primary:hover {
  background-color: $hover-color !important;
  color: white;
}

.error-boundary .error-boundary-footer button {
  background-color: $secondary-color !important;
  color: white;
}

.error-boundary .error-boundary-footer button:hover {
  background-color: $hover-color !important;
  color: white;
}

// Modale di conferma (componente ConfirmModal)

.confirm-modal .confirm-modal-header {
  background: $main-color !important;
}

.confirm-modal .confirm-modal-body .icon-container .question {
  background: $light-main-color !important;
  border-color: $main-color !important;
}

.confirm-modal .confirm-modal-body .icon-container .question i {
  color: $secondary-color !important;
}

.confirm-modal .confirm-modal-footer button.primary {
  background-color: $secondary-color !important;
  color: white;
}

.confirm-modal .confirm-modal-footer button.primary:hover {
  background-color: $hover-color !important;
  color: white;
}

.confirm-modal .confirm-modal-footer button {
  background-color: $secondary-color !important;
  color: white;
}

.confirm-modal .confirm-modal-footer button:hover {
  background-color: $hover-color !important;
  color: white;
}

// Modale di messaggio (componente MessageModal)

.message-modal .message-modal-header {
  background: $main-color !important;
}

.message-modal .message-modal-body .icon-container .success i {
  color: $secondary-color !important;
}

.message-modal .message-modal-footer button.primary {
  background-color: $secondary-color !important;
  color: white;
}

.message-modal .message-modal-footer button.primary:hover {
  background-color: $main-color !important;
  color: white;
}

.message-modal .message-modal-footer button {
  background-color: $secondary-color !important;
  color: white;
}

.message-modal .message-modal-footer button:hover {
  background-color: $main-color !important;
  color: white;
}

.generic-modal .generic-modal-header {
  background-color: $main-color !important;
  color: white;
}

// SessionWarningModal (modale che la sessione sta scadendo)

.session-warning-modal .session-warning-modal-header {
  background: $main-color !important;
  color: white;
}

.session-warning-modal .session-warning-modal-body .icon-container .warning {
  background: $light-main-color !important;
  border-color: $main-color !important;
}

.session-warning-modal .session-warning-modal-body .icon-container .warning i {
  color: $secondary-color !important;
}

.session-warning-modal .session-warning-modal-footer button.primary {
  background-color: $secondary-color !important;
  color: white;
}

.session-warning-modal .session-warning-modal-footer button.primary:hover {
  background-color: $hover-color !important;
  color: white;
}

// ValidationErrorModal (modale per la validazone dei required generic form)

.ValidationErrorModalFooter .ms-Button--default {
  background-color: $secondary-color !important;
  color: white;
}

.ValidationErrorModalFooter .ms-Button--default:hover {
  background-color: $main-color !important;
  color: white;
}
