@function main-color() {
    $main-color: white;
    @if ($env =='portal') {
        $main-color: #a5be19;
    }
    @else if ($env =='portal-clever') {
        $main-color: #92b4ce;
    }
    
    @return $main-color;
}

@function background-grey-color() {
    $background-grey-color: white;
    @if ($env =='portal') {
        $background-grey-color: rgb(255, 255, 255, 0.3);
    }
    @else if ($env =='portal-clever') {
        $background-grey-color: rgb(255, 255, 255, 0.3);
    }
   
    @return $background-grey-color;
}

@function secondary-color() {
    $secondary-color: white;
    @if ($env =='portal') {
        $secondary-color: #676767;
    }
    @else if ($env =='portal-clever') {
        $secondary-color:  #1d5c93;
    }
   
    @return $secondary-color;
}

@function light-main-color() {
    $light-main-color: white;
    @if ($env =='portal') {
        $light-main-color: #edf4d3;
    }
    @else if ($env =='portal-clever') {
        $light-main-color:  #bbd0e6;
    }
   
    @return $light-main-color;
}

@function tertiary-color() {
    $tertiary-color: white;
    @if ($env =='portal') {
        $tertiary-color: #a5be19;
    }
    @else if ($env =='portal-clever') {
        $tertiary-color:  #d5d329;
    }
   
    @return $tertiary-color;
}

@function text-header-color() {
    $text-header-color: white;
    @if ($env =='portal') {
        $text-header-color: #676767;
    }
    @else if ($env =='portal-clever') {
        $text-header-color:  #1d5c93;
    }
   
    @return $text-header-color;
}

@function hover-color() {
    $hover-color: white;
    @if ($env =='portal') {
        $hover-color: #a5be19;
    }
    @else if ($env =='portal-clever') {
        $hover-color:  #92b4ce;
    }
   
    @return $hover-color;
}

$main-color: main-color();
$secondary-color: secondary-color();
$light-main-color: light-main-color();
$tertiary-color: tertiary-color();
$hover-color: hover-color();
$background-grey-color: background-grey-color();
$ultradark-grey-color: rgb(51, 51, 51);
$dark-grey-color: #676767;
$light-grey-color: #d0d0d0;
$ultralight-grey-color: #f0f0f0;

$text-header-color: text-header-color();

$white-color: #ffffff;
$black-color: #000000;

$dark-green-color: rgba(73, 184, 72, 0.5);
$green-color: #49b848;
$dark-yellow-color: rgba(254, 206, 9, 0.5);
$yellow-color: #fece09;
$dark-red-color: rgba(211, 32, 39, 0.5);
$red-color: #d32027;
$blue-color: #0000FF;
