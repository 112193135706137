.DataTable {
  height: calc(100% - 3px);
  display: flex;
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  flex-flow: column;
  -webkit-flex-flow: column;
}

.ContextualMenuDefaultColumnItem button {
  height: 0;
}

.cdDatiFinanziari-right {
  width: 110px;
}

.DataTable .DataTableTitle {
  font-size: 1.25em;
  text-transform: uppercase;
  background: rgb(29, 93, 148);
  color: white;
  height: 1px;
  min-height: 1px;
  max-height: 1px;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  text-align: left;
  display: flex;
}

.DataTable .DataTableTitle span {
  background: rgb(29, 93, 148);
  padding: 5px 10px 5px 10px;
  position: relative;
  top: -10px;
  left: 20px;
}

.DataTable .DataTableBody.tiny-row-body .ms-List-cell, .DataTable .DataTableBody.tiny-row-body .ms-DetailsRow, .DataTable .DataTableBody.tiny-row-body .ms-DetailsRow-cell, .DataTable .DataTableBody.tiny-row-body .dataTableTdMiddle32, .DataTable .DataTableBody.tiny-row-body .ms-DetailsRow-check, .DataTable .DataTableBody.tiny-row-body button {
  /* min-height: initial;
  max-height: initial; */
  /* Su Explorer l'initial non funziona */
  min-height: auto;
  max-height: none;
  height: auto !important;
  padding-top: 1px;
  padding-bottom: 1px;
}

.DataTable .DataTableBody.tiny-row-body .ms-List-cell .ms-ComboBox button {
  height: 32px !important;
}

.DataTable .DataTableBody.tiny-row-body .dataTableTdMiddle32[class*="ms-font-"] .DataTableTd {
  height: 0;
}

/* rimosse da core perch� proprietarie di clever, e rifatte in modo da gestire l'hover
.DataTable .DataTableBody.cdDatiFinanziariTable .ms-List-cell, .DataTable .DataTableBody.cdDatiFinanziariTable .ms-DetailsRow, .DataTable .DataTableBody.cdDatiFinanziariTable .ms-DetailsRow-cell, .DataTable .DataTableBody.cdDatiFinanziariTable .dataTableTdMiddle32, .DataTable .DataTableBody.cdDatiFinanziariTable .ms-DetailsRow-check, .DataTable .DataTableBody.cdDatiFinanziariTable button {
  padding-top: 0px;
  padding-bottom: 0px;
}

.DataTable .DataTableBody.cdDatiFinanziariTable .ms-List-cell {
  border-bottom: #eee;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.DataTable .DataTableBody.cdDatiFinanziariTable .ms-DetailsHeader-cellTitle {
  justify-content: center;
  font-weight: bold;
  padding: 0;
}

.DataTable .DataTableBody.cdDatiFinanziariTable .reserveCell {
  background-color: #fbfbfb;
  height: 100% !important;
}

.DataTable .DataTableBody.cdDatiFinanziariTable .reserveCell i {
  color: #1d5d94;
  font-weight: bolder;
}

.DataTable .DataTableBody.cdDatiFinanziariTable .importCell {
  background-color: rgba(220, 220, 220, 0.72);
  height: 100% !important;
}

.DataTable .DataTableBody.cdDatiFinanziariTable .importCell i {
  color: #1d5d94;
  font-weight: bolder;
}
*/

.DataTable .DataTableTopRightButton .ms-Button-menuIcon, .DataTable .DataTableTopRightButton span {
  display: none;
}

.DataTable .smallDataTableTitle {
  font-size: 1.25em;
  text-transform: uppercase;
  background-color: rgb(29, 93, 148);
  color: white;
  height: 1px;
  min-height: 1px;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  text-align: left;
  display: flex;
}

.DataTable .smallDataTableTitle span {
  background-color: rgb(29, 93, 148);
  padding: 5px 10px;
  position: relative;
  top: -10px;
  left: 20px;
  font-size: smaller;
  font-weight: bold;
}

.DataTable .top-right-button {
  float: right;
  left: -10px !important;
  padding: 0 !important;
  top: -15px !important;
}

.DataTable .top-right-button i {
  color: white;
}

.DataTable .icon-red button i {
  color: red;
}

.DataTable .ms-DetailsRow-cell {
  padding-top: 0px;
  padding-bottom: 0px;
  /* height: 100%; */
  cursor: default !important;
}

.DataTable .ms-DetailsRow-fields {
  cursor: default !important;
}

.DataTable .scadenziario-body {
  padding: 0px;
  background-color: #edefee;
}

.DataTable .scadenziario-group-row0 {
  border-top: solid 0.5px #edefee;
}

.DataTable .is-selected.scadenziario-group-row0 {
  background-color: rgb(212, 216, 221);
}

.DataTable .scadenziario-group-row1 {
  border-top: solid 0.5px #edefee;
  background-color: #eff4f9;
}

.DataTable .scadenziario-group-row0:hover, .DataTable .scadenziario-group-row1:hover, .DataTable .is-selected.scadenziario-group-row0:hover, .DataTable .is-selected.scadenziario-group-row1:hover, .DataTable .scadenziario-detail-row0:hover, .DataTable .scadenziario-detail-row1:hover, .DataTable .is-selected.scadenziario-detail-row0:hover, .DataTable .is-selected.scadenziario-detail-row1:hover, .DataTable .scadenziario-nogroup-row0:hover, .DataTable .scadenziario-nogroup-row1:hover, .DataTable .is-selected.scadenziario-nogroup-row0:hover, .DataTable .is-selected.scadenziario-nogroup-row1:hover {
  background-color: #bbd0e6;
}

.DataTable .is-selected.scadenziario-group-row1 {
  background-color: #cadbeb;
}

.DataTable .scadenziario-header-row {
  background-color: #f0efbb;
  text-transform: uppercase;
  font-weight: bold;
}

.DataTable .scadenziario-header-row div {
  font-size: 12px;
  font-weight: bold;
}

.DataTable .scadenziario-detail-row0 {
  font-weight: lighter;
  min-height: inherit;
}

.DataTable .scadenziario-detail-row0 .IconSwitchComponent button:disabled {
  background-color: #fff;
}

.DataTable .scadenziario-detail-row1 {
  font-weight: lighter;
  background-color: #eff4f9;
  min-height: inherit;
  width: 100%;
}

.DataTable .is-selected.scadenziario-detail-row0 {
  background-color: rgb(212, 216, 221);
}

.DataTable .is-selected.scadenziario-detail-row1 {
  background-color: #cadbeb;
}

.DataTable .scadenziario-nogroup-row0 {
  border-top: solid 0.5px #edefee;
}

.DataTable .is-selected.scadenziario-nogroup-row0 {
  background-color: rgb(212, 216, 221);
}

.DataTable .scadenziario-nogroup-row1 {
  border-top: solid 0.5px #edefee;
  background-color: #eff4f9;
}

.DataTable .is-selected.scadenziario-nogroup-row1 {
  background-color: #cadbeb;
}

.DataTable .color-2 {
  color: #de2304;
  text-decoration: none;
}

.DataTable .color-0 {
  color: #55ca25;
  text-decoration: none;
}

.DataTable .color-1 {
  color: #d7d229;
  text-decoration: none;
}

.DataTable .color-3 {
  color: lightgrey;
  text-decoration: none;
}

.DataTable .background-color-2 {
  background-color: #fbebe9;
}

.DataTable .background-color-0 {
  background-color: #d1edc2;
}

.DataTable .background-color-1 {
  background-color: #f5f4cf;
}

.DataTable .background-detector-o {
  background-color: #ffa500;
  font-weight: bold;
}

.DataTable .background-detector-r {
  background-color: #ff0000;
  font-weight: bold;
}

.DataTable .background-detector-y {
  background-color: #ffff00;
  font-weight: bold;
}

.DataTable .bold {
  font-weight: bold;
}

.DataTable .bold i {
  font-weight: bold;
}

.DataTable .icon-no-margin i {
  margin: 0;
}

.DataTable a,
.DataTable button.wagx-link,
.DataTable button.wagx-send-data,
.DataTable button.wagx-send-data i {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  font-size: 12px;
  line-height: unset;
  text-align: unset;
  vertical-align: unset;
  flex-shrink: unset;
  margin: 0px;
  height: auto;
}

.DataTable .hover-link a,
.DataTable .hover-link button.wagx-link,
.DataTable .hover-link button.wagx-send-data {
  color: rgb(51, 51, 51);
  text-decoration: none;
}

.DataTable .hover-link a:hover,
.DataTable .hover-link button.wagx-link:hover,
.DataTable .hover-link button.wagx-send-data:hover {
  color: rgb(0, 0, 238);
}

.DataTable .ms-ScrollablePane--contentContainer {
  overflow-y: scroll !important;
}

.DataTable .ms-DetailsList {
  overflow: visible !important;
}

.DataTable .dataTableTdMiddle32 {
  display: flex;
  align-items: center;
  height: 30px;
  white-space: pre-wrap;
}

.column-clear-filter-button {
  right: 0;
  position: absolute;
  top: 0;
}

.DataTable .ms-DetailsRow-cell {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.DataTableTop {
  flex: 0 1 auto;
  display: flex;
  flex-direction: column;
}

.DataTableTop .DataTableTitle-xs-yellow {
  font-size: 1em;
  font-weight: bold;
  text-transform: uppercase;
  background: rgb(141, 141, 138);
  color: rgb(51, 51, 51);
  width: 100%;
  height: 1px;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  display: flex;
}

.DataTableTop .DataTableTitle-xs-yellow span {
  background: #f0efbb;
  padding: 5px 10px 5px 10px;
  position: relative;
  top: -10px;
  left: 20px;
}

.DataTableTop .DataTableTitle-xs-yellow span.top-right-button {
  background: #ffffff;
  padding: 5px 10px 5px 10px;
  position: relative;
  top: -10px;
  left: 20px;
}

.DataTableTop .DataTableTitle-xs-yellow .DataTableTopRightButton i {
  color: rgb(51, 51, 51);
}

.DataTableTop .DataTableTitle-xs {
  font-size: 1em;
  font-weight: bold;
  text-transform: uppercase;
  background: rgb(29, 93, 148);
  color: white;
  width: 100%;
  height: 1px;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  display: flex;
}

.DataTableTop .DataTableTitle-xs span {
  background: rgb(29, 93, 148);
  padding: 5px 10px 5px 10px;
  position: relative;
  top: -10px;
  left: 20px;
}

.DataTableTop .DataTableTitle-xs-white {
  font-size: 1em;
  font-weight: bold;
  text-transform: uppercase;
  background: rgb(29, 93, 148);
  color: rgb(29, 93, 148);
  width: 100%;
  height: 1px;
  margin-top: 1.5em;
  margin-bottom: 1em;
  display: flex;
  text-align: left;
}

.DataTableTop .DataTableTitle-desk-xs-white {
  font-size: 1em;
  font-weight: bold;
  text-transform: uppercase;
  background: #5c6c9e;
  color: #fff;
  height: 2em;
  display: flex;
}

.DataTableTop .title-string .GenericInputLabelValue {
  display: inline !important;
}

.DataTableTop .DataTableTitle-xs-white .title-string {
  white-space: nowrap;
  max-width: calc(100% - 90px);
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.DataTableTop .DataTableTitle-xs-white span {
  background: white;
  padding: 0px 10px 0px 10px;
  position: relative;
  top: -10px;
  left: 20px;
}

.DataTableTop .DataTableTitle-desk-xs-white .title-string {
  white-space: nowrap;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 5px;
}

.DataTableTop .DataTableTitle-xs-white i {
  color: rgb(29, 93, 148);
}

.DataTableTop .DataTableTitle-xs-white label {
  color: rgb(29, 93, 148);
  text-transform: uppercase;
  font-weight: bold;
  padding: 0;
}

.DataTableTop .DataTableTitleContainer {
  /* display: flex; */
  width: 80%;
}

.DataTableTop .DataTableActionContainer {
  width: 20%;
}

.DataTableBodyContainer {
  flex: 1 1 auto;
}

.DataTablePagination {
  flex: 0 1 40px;
}

.match-moviemnto-bancario-left-body .dataTableTdMiddle32 {
  max-height: unset !important;
  padding-top: 5px;
  padding-bottom: 5px;
  display: block;
}

.PiPartitaDannoDataTableBody .ms-ScrollablePane--contentContainer {
  overflow-x: hidden !important;
  overflow-y: auto !important;
  background-color: #fbf9e9;
}

.canalizza-incarico-1-carrozzeria-0 i {
  color: red !important;
}

.canalizza-incarico-0-carrozzeria-1 i, .canalizza-incarico-1-carrozzeria-1 i {
  color: chartreuse !important;
}

.DataTable .cdPresaVisione-normal {
  color: black;
}

.DataTable .cdPresaVisione-bold {
  font-weight: bold;
  color: blue;
}

.DataTable .cdPresaVisione-italic {
  color: blue;
  font-style: italic;
}

.DataTable .cdInterventoIt {
  color: rgb(146, 143, 143);
  font-weight: bolder;
}

.DataTable .textColorBlue {
  color: blue;
}

.display-none {
  display: none;
}

.cell-singleline-ellipsis .DataTableTd {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.cdSinistriTestataOverFlow .red, .ClaimDesk-DatiSinistroBox .red {
  color: red;
}

.filters-on-title-container {
  float: right;
  position: relative;
  top: -1em;
  background-color: #fff;
  padding: 0px 10px;
  right: 10px;
}

.filters-on-title-container .ms-layer {
  background-color: transparent !important;
  /* Workaround per toggle su titolo della datatable */
}

.SwitchFAttivoTrue {
  color: green;
  font-size: 18px;
}

.SwitchFAttivoFalse {
  color: red;
  font-size: 18px;
}

.DataTable .DataTableBodyContainer .DataTableBodyContent .CheckedButtonHidden .ms-Check {
  display: none;
}

.iconLarge div i {
  font-size: 18px;
  margin-top: 5px;
  margin-left: 9px;
}

.iconDelete-generaDistinte {
  margin-top: -8px;
  margin-right: 10px;
}

.DataTableAzureYellowTheme .DataTableTop .DataTableTitle {
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto", "Helvetica Neue", sans-serif;
  font-weight: bold;
  padding: 5px;
  background-color: #f0efbb;
  color: black;
  height: auto;
  max-height: none;
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 15px;
}

.DataTableAzureYellowTheme .DataTableTop .DataTableTitle span {
  position: static;
  background: none;
  padding: 0;
  top: 0px;
  left: 0px;
}

.DataTableAzureYellowTheme .DataTableTop .DataTableTitle .top-right-button button {
  height: 0;
  padding-top: 10px;
}

.DataTableAzureYellowTheme .DataTableTop .DataTableTitle .top-right-button button i {
  color: black;
  font-weight: bold;
}

.DataTable .ms-DetailsHeader-cell.allinea-centro-header span,
.DataTable .ms-DetailsHeader-cell.ms-textAlignCenter span {
  display: flex;
  justify-content: center;
}

.DataTable .ms-DetailsHeader-cell.allinea-destra-header span,
.DataTable .ms-DetailsHeader-cell.ms-textAlignRight span {
  justify-content: flex-end;
}

.DataTable .ms-DetailsHeader-cell.allinea-sinistra-header span,
.DataTable .ms-DetailsHeader-cell.ms-textAlignLeft span {
  justify-content: left;
}

.DataTable .margin-left-6 div {
  margin-left: 6px;
}

.DataTable .margin-left-8 div {
  margin-left: 8px;
}

.DataTable .margin-left-10 div {
  margin-left: 10px;
}

.DataTable .margin-left-12 div {
  margin-left: 12px;
}

.DataTable .margin-left-13 div {
  margin-left: 13px;
}

.DataTable .margin-right-6 div {
  margin-right: 6px;
}

.DataTable .margin-right-8 div {
  margin-right: 8px;
}

.DataTable .margin-right-10 div {
  margin-right: 10px;
}

.DataTable .margin-right-12 div {
  margin-right: 12px;
}

.DataTable .margin-right-13 div {
  margin-right: 13px;
}

.DataTable .icon-small-grouped-row button {
  height: auto;
}

.DataTable .icon-small-grouped-row button i {
  font-size: 12px;
}

.DataTable .overflow-x-scroll {
  overflow-x: auto;
}

.DataTable.data-table-modern-white-theme .ms-DetailsHeader,
.DataTable.data-table-modern-white-theme .ms-DetailsHeader-cell,
.DataTable.data-table-modern-white-theme .ms-DetailsHeader-cellTitle,
.DataTable.data-table-modern-white-theme .dataTableTdMiddle32 {
  padding: 0px;
  border: 0px;
}

.DataTable.data-table-modern-white-theme .ms-DetailsHeader-cellTitle,
.DataTable.data-table-modern-white-theme .dataTableTdMiddle32 {
  padding-left: 5px;
  padding-right: 5px;
}

.DataTable.data-table-modern-white-theme .ms-DetailsHeader-cell,
.DataTable.data-table-modern-white-theme .ms-DetailsRow-cell {
  border-left: 2px solid lightgray;
  border-bottom: 2px solid lightgray;
  margin-left: -1px;
}

.DataTable.data-table-modern-white-theme .ms-DetailsHeader-cell.ms-textAlignCenter .ms-DetailsHeader-cellTitle {
  justify-content: center;
}

.DataTable.data-table-modern-white-theme .ms-DetailsHeader-cell.ms-textAlignRight .ms-DetailsHeader-cellTitle {
  justify-content: flex-end;
}

.DataTable.data-table-modern-white-theme .dataTableTdMiddle32 {
  font-weight: bold;
}

.DataTable.data-table-modern-white-theme i.ms-Check-circle {
  height: 23px;
  width: 23px;
  left: -5px;
  top: -4px;
  background-color: white;
  border: 2px solid lightgray;
  border-radius: 15px;
  color: transparent !important;
}

.DataTable.data-table-modern-white-theme i.ms-Check-check {
  height: 15px;
  width: 15px;
  top: 2px;
  background-color: rgb(29, 92, 147);
  color: rgb(29, 92, 147);
  border-radius: 10px;
}

.DataTable .ms-DetailsRow.data-table-modern-white-theme-detail-row0 {
  background: white !important;
}

.DataTable .ms-DetailsRow.data-table-modern-white-theme-detail-row1 {
  background: rgb(244, 246, 248);
}

.DataTable .DataTableTd button {
  padding: 0px;
  width: auto;
  height: auto;
}

.DataTable .DataTableTd i.disabled {
  opacity: 0.5;
}

.DataTable .DataTableTd i.ms-Button-icon {
  font-size: 12px;
}

.DataTable .data-table-footer-action-container {
  height: 50px;
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.DataTable .data-table-footer-action-container button {
  min-width: 150px;
}

.DataTable .data-table-footer-action-container button:first-child {
  margin-left: 20px;
}

.DataTable .data-table-footer-action-container button:last-child {
  margin-right: 20px;
}

.DataTable .data-table-footer-action-container button.primary {
  background-color: rgb(29, 92, 147);
  color: white;
}

.DataTable .data-table-footer-action-container button.primary:hover {
  background-color: rgb(16, 110, 190);
  color: white;
}

.DataTable .data-table-footer-action-container button.primary.is-disabled {
  opacity: 0.5;
}

.DataTable.DataTableChildSelection .ms-DetailsHeader {
  background: #f0efbb;
}

.DataTable.DataTableChildSelection .ms-DetailsHeader .ms-DetailsHeader-cell[data-item-key="expandButton"] {
  width: 0 !important;
}

.DataTable.DataTableChildSelection .ms-DetailsHeader .ms-DetailsHeader-cell[data-item-key="itemsCount"] {
  width: 32px !important;
}

.DataTable.DataTableChildSelection .ms-DetailsHeader .ms-DetailsHeader-cell,
.DataTable.DataTableChildSelection .ms-DetailsHeader .ms-DetailsHeader-cell span.ms-DetailsHeader-cellTitle {
  padding: 0px !important;
  margin: 0px !important;
}

.DataTable.DataTableChildSelection .group-header-row {
  display: inline-flex;
  min-width: 100%;
  min-height: 32px;
}

.DataTable.DataTableChildSelection .group-header-row.scadenziario-group-row0 {
  background-color: white;
  border: 0 !important;
}

.DataTable.DataTableChildSelection .group-header-row.scadenziario-group-row1 {
  background-color: #eff4f9;
  border: 0 !important;
}

.DataTable.DataTableChildSelection .group-header-row .group-header-selection {
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.DataTable.DataTableChildSelection .group-header-row .group-header-container {
  min-height: 32px;
  display: inline-flex;
}


.DataTable.DataTableChildSelection .group-header-container .ms-DetailsRow-cell.expandButton,
.DataTable.DataTableChildSelection .group-header-container .ms-DetailsRow-cell.itemsCount {
  width: 32px !important;
  font-size: 12px;
  text-align: center;
}

.DataTable.DataTableChildSelection .group-header-container .ms-DetailsRow-cell .dataTableTdMiddle30 {
  display: flex;
  align-items: center;
  height: 30px;
  white-space: pre-wrap;
}

.DataTable.DataTableChildSelection .group-detail-container {
  background-color: white;
  width: 100%;
}

.DataTable.DataTableChildSelection .group-detail-container .ms-DetailsRow-fields {
  width: 100%;
  font-style: italic;
}

.DataTable.DataTableChildSelection .group-detail-container .ms-DetailsRow-fields .ms-DetailsRow-cell {
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}